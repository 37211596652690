"use client";

import { Col, Row, Typography } from "antd";
import useMySWR from "@/hooks/useMySWR";
import dayjs from "dayjs";
import en from "dayjs/locale/en";

import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import DataItem from "@/app/components/Home/DataItem";
import SentimentSummaryChart from "@/app/components/Home/SentimentSummaryChart";
import SummaryChart from "@/app/components/Home/SummaryChart";
import PubCountChart from "@/app/components/Home/PubCountChart";
import LatestReview from "@/app/components/Home/LatestReview";
import PubSummary from "@/app/components/Home/PubSummary";

dayjs.locale({
  ...en,
  weekStart: 1,
});

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export interface ILatestChart {
  name: string;
  score: number;
  no_reviewer: number;
  positive: number;
  negative: number;
  neutral: number;
  updated_at: string;
  _id: string;
}

export default function Home() {
  const { data: latestData } = useMySWR<{ data: ILatestChart[] }>(["v1/sentiment/latest"], {
    method: "get",
  });
  return (
    <>
      <Typography.Title level={1} className="text-3xl leading-10 text-black-2 font-medium m-0 mb-4">
        Latest Data
      </Typography.Title>
      <Row gutter={[16, 16]} className="mb-4">
        {latestData?.data?.map((item, index) => (
          <Col key={index} lg={8} span={24}>
            <DataItem item={item} />
          </Col>
        ))}
      </Row>
      <Row gutter={[16, 16]} className="mb-4">
        <Col xl={16} span={24}>
          <SentimentSummaryChart />
        </Col>
        <Col xl={8} span={24}>
          <Row className="h-full" gutter={[16, 16]}>
            <Col className="split-chart" span={24}>
              <SummaryChart />
            </Col>
            <Col className="split-chart" span={24}>
              <PubCountChart />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={8} span={24}>
          <LatestReview />
        </Col>
        <Col xl={16} span={24}>
          <PubSummary />
        </Col>
      </Row>
    </>
  );
}
