import { Card, Space, Typography } from "antd";
import Image from "next/image";
import EnhanceReactEchart from "../EnhanceReactEchart";
import useMySWR from "@/hooks/useMySWR";

interface ISummary {
  count: number;
  change: number;
  positive: number;
  negative: number;
  neutral: number;
}

const SummaryChart = () => {
  const { data: response, isLoading } = useMySWR<{ data: ISummary }>(["v1/sentiment/summary"], {
    method: "get",
  });
  return (
    <Card
      styles={{ body: { padding: "16px 24px", height: "100%" } }}
      className="border-none rounded-2xl h-full min-h-[223px]"
    >
      <Typography.Title level={5} className="text-2xl m-0 font-medium text-black-2 mb-6">
        Summary
      </Typography.Title>
      <div className="flex flex-col md:flex-row items-center justify-between flex-nowrap">
        <div className="flex flex-col gap-2">
          <Typography.Title className="font-semibold text-[32px] leading-[41px] text-main-1 m-0" level={5}>
            {response?.data?.count}
          </Typography.Title>
          <Space>
            <div className="bg-[#DCFAF8] rounded-full w-6 h-6 flex items-center justify-center p-0 ">
              <Image className="object-cover" width={10} height={10} src={"/home/arrow-top-left.svg"} alt="arrow" />
            </div>
            <Typography.Text className="text-black-2 font-medium whitespace-nowrap">
              {response?.data?.change} <Typography.Text className="text-black-1">last year</Typography.Text>
            </Typography.Text>
          </Space>
        </div>
        <div className="h-[142px] w-[142px]">
          <EnhanceReactEchart
            className="h-[142px] w-[142px]"
            showLoading={isLoading}
            option={{
              color: ["#16DBCC", "#FFBB38"],
              tooltip: {
                show: true,
              },
              series: [
                {
                  name: "",
                  type: "pie",
                  radius: ["60%", "95%"],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 4,
                    borderColor: "#fff",
                    borderWidth: 2,
                  },
                  label: {
                    show: false,
                  },
                  emphasis: {
                    label: {
                      show: false,
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  data: [
                    { value: response?.data?.positive, name: "Positive", color: "#16DBCC" },
                    { value: response?.data?.negative, name: "Negative", color: "#FFBB38" },
                  ],
                },
              ],
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default SummaryChart;
