import { Card, Timeline, Typography } from "antd";
import useMySWR from "@/hooks/useMySWR";
import dayjs from "dayjs";

interface ILatestReview {
  text: string;
  project: string;
  updated_at: string;
}
const LatestReview = () => {
  const { data: response } = useMySWR<{ data: ILatestReview[] }>(["v1/sentiment/review"], {
    method: "get",
  });
  const data = response?.data?.map((item : any) => ({
    dot: <div className="w-3 h-3 border border-solid border-main-1 rounded-full bg-primary" />,
    children: (
      <>
        <Typography.Title level={5} className="text-base font-semibold">
          {item.text}
        </Typography.Title>
        <Typography.Text className="text-xs font-semibold text-gray-3">
          {dayjs(item.updated_at).format("DD MMM h:mm A")} - {item.project}
        </Typography.Text>
      </>
    ),
  }));

  return (
    <Card styles={{ body: { paddingRight: "8px", height: "100%" } }} className="border-none rounded-2xl h-full">
      <Typography.Title level={5} className="text-2xl m-0 font-medium text-black-2 mb-6">
        Latest Review
      </Typography.Title>
      <div className="max-h-[350px] overflow-y-auto px-1 py-1">
        <Timeline items={data} />
      </div>
    </Card>
  );
};

export default LatestReview;
