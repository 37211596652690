import useMySWR from "@/hooks/useMySWR";
import { Card, Typography } from "antd";
import dayjs from "dayjs";
import EnhanceReactEchart from "../EnhanceReactEchart";

interface IPubCount {
  count: number;
  change: number;
  date: string[];
  data_chart: number[];
}

const PubCountChart = () => {
  const { data: response, isLoading } = useMySWR<{ data: IPubCount }>(["v1/sentiment/count"], {
    method: "get",
  });
  return (
    <Card
      styles={{ body: { padding: "16px 24px", height: "100%" } }}
      className="border-none rounded-2xl h-full min-h-[223px]"
    >
      <Typography.Title level={5} className="text-2xl m-0 font-medium text-black-2 mb-3">
        Pub count
      </Typography.Title>
      <div className="flex flex-col md:flex-row items-center gap-4">
        <Typography.Title className="font-semibold text-[32px] leading-[41px] text-main-1 m-0" level={5}>
          {response?.data?.count}
        </Typography.Title>
      </div>
      <div className="absolute right-0 bottom-0 left-0 h-[91px]">
        <EnhanceReactEchart
          className="h-[91px]"
          showLoading={isLoading}
          option={{
            tooltip: {
              trigger: "axis",
              formatter: (item: any) => item,
              axisPointer: {
                type: "cross",
                label: {
                  color: "#2D60FF",
                  backgroundColor: "#fafafa",
                },
              },
            },
            grid: {
              bottom: "3%",
              top: "1%",
              left: "100%",
              right: "100%",
            },
            xAxis: {
              show: false,
              type: "category",
              boundaryGap: false,
              data: response?.data?.date,
            },
            yAxis: {
              show: false,
              type: "value",
            },
            series: [
              {
                name: "Line 1",
                type: "line",
                smooth: true,
                lineStyle: {
                  width: 2,
                  color: "#2D60FF",
                },
                showSymbol: false,
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(45, 96, 255, 0.25)", // Start color
                      },
                      {
                        offset: 1,
                        color: "rgba(45, 96, 255, 0)", // End color
                      },
                    ],
                  },
                },
                emphasis: {
                  focus: "series",
                },
                data: response?.data?.data_chart,
              },
            ],
          }}
        />
      </div>
    </Card>
  );
};

export default PubCountChart;
