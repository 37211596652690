import useMySWR from "@/hooks/useMySWR";
import { Card, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import EnhanceReactEchart from "../EnhanceReactEchart";

interface ISummaryChart {
  date: string[];
  positive: number[];
  negative: number[];
}

const SentimentSummaryChart = () => {
  const { data: response, isLoading } = useMySWR<{ data: ISummaryChart }>(["v1/sentiment/chart"], {
    method: "get",
  });
  const series = useMemo(
    () => [
      {
        data: response?.data?.positive,
        type: "bar",
        name: "Positive 😄",
        yAxisIndex: 0,
        barWidth: 12,
        barGap: 0.5,
      },
      {
        data: response?.data?.negative,
        type: "bar",
        name: "Negative 😡",
        yAxisIndex: 1,
        barWidth: 12,
        barGap: 0.5,
      },
    ],
    [response?.data?.negative, response?.data?.positive],
  );
  const addStyleSeries = series.map(i => ({
    ...i,
    itemStyle: {
      barBorderRadius: 16,
    },
  }));
  return (
    <Card styles={{ body: { height: "100%" } }} className="border-none rounded-2xl h-full">
      <Space className="w-full justify-between mb-8">
        <Typography.Title level={5} className="text-2xl m-0 font-medium text-black-2">
          Sentiment Summary Chart
        </Typography.Title>
      </Space>
      <div>
        <EnhanceReactEchart
          className="h-[350px]"
          showLoading={isLoading}
          option={{
            color: ["#16DBCC", "#FFBB38"],
            grid: {
              bottom: "18%", // Adjust the bottom margin
              top: "18%", // Adjust the top margin
            },
            legend: {
              bottom: 0,
              icon: "circle",
              itemGap: 39,
            },
            tooltip: {},
            xAxis: {
              type: "category",
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  color: "#F3F3F5",
                },
              },
              axisLabel: {
                color: "#343C6A",
                formatter: function (value: string) {
                  return value;
                },
              },
              data: response?.data?.date,
            },
            yAxis: [
              {
                type: "value",
                name: "Counts",
                min: 0,
                max: 8000,
                interval: 2000,
                axisLabel: {
                  formatter: "{value}",
                  color: "#343C6A",
                },
                splitLine: {
                  lineStyle: {
                    color: "#F3F3F5",
                  },
                },
              },
              {
                type: "value",
                name: "Counts",
                min: 0,
                max: 1000,
                interval: 250,
                axisLabel: {
                  formatter: "{value}",
                  color: "#343C6A",
                },
                splitLine: {
                  show: false,
                },
              },
            ],
            series: addStyleSeries,
          }}
        />
      </div>
    </Card>
  );
};

export default SentimentSummaryChart;
