"use client";
import type { PaginationProps, TableProps } from "antd";
import { Avatar, Card, Space, Table, Typography } from "antd";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useMySWR from "@/hooks/useMySWR";
import { useRouter } from "next/navigation";

interface DataType {
  key: string;
  name: string;
  avatar: string;
  rating: string;
  range: string;
  score: number;
  _id: string;
  url: string;
  imageUrl: string;
  positive: number;
  negative: number;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "RANK",
    dataIndex: "rank",
    key: "rank",
    render: text => <Typography.Text className="text-black-2">{text}</Typography.Text>,
  },
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
    render: (text, { imageUrl }) => (
      <Space>
        <Avatar size={24} src={imageUrl} />
        <Typography.Text className="text-base text-[#232323]">{text}</Typography.Text>
      </Space>
    ),
  },
  {
    title: "RATING",
    dataIndex: "rating",
    key: "rating",
    render: text => (
      <Space className="items-center">
        <Typography.Text className="text-black-2">{text}</Typography.Text>
        <FontAwesomeIcon className={"w-[16px] h-[16px] text-yellow"} icon={faStar} />
      </Space>
    ),
  },
  {
    title: "POSITIVE 😄/NEGATIVE 😡",
    key: "range",
    dataIndex: "range",
    render: (_, { positive, negative }) => (
      <Typography.Text className="text-black-2">{`${positive}/${negative}`}</Typography.Text>
    ),
  },
  {
    title: "SENTIMENT SCORE",
    key: "score",
    dataIndex: "score",
    render: text => <Typography.Text className="text-black-2">{text}/100</Typography.Text>,
  },
];

const showTotal: PaginationProps["showTotal"] = total => (
  <Typography.Text className="text-black-1">{`Total ${total} items`}</Typography.Text>
);

const PubSummary = () => {
  const router = useRouter();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);
  const { data: response, isLoading } = useMySWR<{ data: { result: DataType[]; total: number } }>(
    ["v1/sentiment/pub-summary", page, limit],
    {
      dataOrParams: { page, limit },
    },
  );
  return (
    <Card styles={{ body: { padding: "16px 24px", height: "100%" } }} className="border-none rounded-2xl h-full">
      <Typography.Title level={5} className="text-2xl m-0 font-medium text-black-2 mb-6">
        Pub Summary
      </Typography.Title>
      <Table
        columns={columns}
        loading={isLoading}
        dataSource={response?.data?.result}
        size="middle"
        onRow={record => ({
          className: "cursor-pointer",
          onClick: () => router.push(`/data/${record._id}`),
        })}
        pagination={{
          pageSize: 5,
          total: response?.data?.total,
          showTotal: showTotal,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{ x: 800 }}
      />
    </Card>
  );
};

export default PubSummary;
