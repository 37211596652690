"use client";
import type { ComponentProps } from "react";
import { lazy, Suspense, useEffect, useRef } from "react";

const ReactECharts = lazy(() => import("echarts-for-react"));

type Prop = ComponentProps<typeof ReactECharts>;

const EnhanceReactEchart = (props: Prop) => {
  const echartRef = useRef(null);

  useEffect(() => {
    (echartRef.current as any)?.resize();
  }, []);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <ReactECharts ref={echartRef} {...props} />
    </Suspense>
  );
};

export default EnhanceReactEchart;
