import { Button, Card, Progress, Rate, Space, Typography } from "antd";
import Image from "next/image";
import type { ILatestChart } from "@/app/page";
import dayjs from "dayjs";
import { ellipsisString } from "@/app/utils";
import { useRouter } from "next/navigation";

const DataItem = ({ item }: { item: ILatestChart }) => {
  const router = useRouter();
  
  return (
    <Card className="border-none rounded-2xl">
      <div className="flex flex-col gap-4">
        <Space className="w-full justify-between">
          <div className="flex items-center gap-2">
            <Typography.Title level={5} className="text-2xl m-0 font-medium" title={item.name}>
              {ellipsisString(item.name, 20)}
            </Typography.Title>
            <Typography.Text className="text-gray-1"> {dayjs(item?.updated_at).fromNow()}</Typography.Text>
          </div>
          <Button
            onClick={() => router.push(`/data/${item._id}`)}
            className="bg-gray-2 rounded-full w-10 h-10 flex items-center justify-center p-0 border-none"
          >
            <Image className="object-cover" width={13.5} height={13.5} src={"/home/arrow-top-right.svg"} alt="arrow" />
          </Button>
        </Space>
        <div className="flex w-full justify-between gap-[25px]">
          <div className="flex flex-col gap-2">
            <Typography.Text className="text-black-1">Overall Score</Typography.Text>
            <Space direction="vertical" size={0}>
              <Typography.Text className="text-[32px] font-semibold leading-[40px]">
                {item.score} <Typography.Text className="text-base font-semibold leading-[20px]">/ 5</Typography.Text>
              </Typography.Text>
              <Rate className="text-[15px] text-yellow" value={item.score} />
            </Space>
          </div>
          <div className="flex-col gap-2 flex-1">
            {[
              { name: "Positive 😄", percent: item.positive },
              { name: "Negative 😡", percent: item.negative },
              { name: "Neutral 😐", percent: item.neutral },
            ].map((chart, index) => (
              <div key={index} className="flex flex-col gap-1">
                <Typography.Title level={5} className="m-0 font-bold leading-[18px]">
                  {chart.name}
                </Typography.Title>
                <Progress
                  strokeColor={"#2D60FF"}
                  format={percent => <div>{percent && (percent / 10).toFixed(2)}</div>}
                  percent={chart.percent}
                  size="small"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DataItem;
